import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FaRegHeart } from "react-icons/fa6";
import { SlHandbag } from "react-icons/sl";
import { IoSearchOutline } from "react-icons/io5";
import { HiOutlineBars3BottomLeft } from "react-icons/hi2";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import logo from "./Homepage/Images/brasslogo.webp";
import { getUserdata } from "../utilities/sessionexpiry";
import { AK } from "../constants/AppKeys";
import { AxiosError } from "../utilities/sessionexpiry";
import { AxiosPost } from "../utilities/axioscall";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import { handleGetCartInfoStorageItems } from "../utilities/cartManager";
import { faChevronDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {
  faFlag,
  faAngleDown,
  faCaretRight,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import "../pages/customstyle.css";
export default function NavbarMain(props) {
  const { storeid, count, setCount, wishlist, setwishlist } = props;
  const [cartinfoData, setCartInfoData] = useState({
    cartcount: 0,
    cartquantitycount: 0,
    products: [],
    cartprice: 0,
    subtotal: 0,
    shipping: 0,
    ordertotal: 0,
    total: 0,
  });
  const [categoryproducts, setCategorypageproducts] = useState([]);
  const [userData, setUserData] = useState(getUserdata());
  const [pageinit, setPageInit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("**NavbarMain**");
    handleGetCartInfoStorageItems(setCartInfoData);
    setUserData(getUserdata());
    setCount({ ...count, count: count + 1 });
    if (count) {
      // console.log("***");
    } else {
      // console.log("***");
    }
  }, [count]);

  // const [wishlist, setwishlist] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const handleItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };
  const {
    store,
    description,
    assets,
    assetsUrl,
    productsListData,
    categoryListData,
    hdimage,
    productimage,
    productviewimage,
    thumbnailviewimage,
    // cartinfoData,
    pageRefresh,
    footercopyrighttext,
    showFooter,
    setshowFooter,
  } = props;

  const onClick_filterCategory = (categoryId, checked) => {
    if (categoryId === 1 && checked) {
      setAvailabeData({
        colorcodes: [{ id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }],
        size: [{ id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }],
        products: productsListData.slice(0, 100),
      });
      setProductsListDisplayData(productsListData.slice(0, 100));
    } else {
      let prod = productsListData.filter((product) => {
        let catlistfilter = categoryListData.filter((catlist) => {
          if (product?.cat_id === undefined || product?.cat_id === null)
            return false;
          return (
            catlist.checked === true &&
            product.cat_id.toString() === catlist.id.toString().padStart(3, "0")
          );
        });

        return catlistfilter.length > 0;
      });
      setProductsListDisplayData(prod.slice(0, 100));
      setAvailabeData({
        colorcodes: [{ id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }],
        size: [{ id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }],
        products: prod,
      });
      if (prod.length === 0)
        setAvailabeData({
          colorcodes: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
          size: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
          products: [
            { id: 110 },
            { id: 111 },
            { id: 112 },
            { id: 113 },
            { id: 114 },
          ],
        });
    }
  };

  useEffect(() => {
    // console.log("***");
  }, [count]);
  const categoryhomepageproducts = useCallback(async () => {
    await AxiosPost(
      AK.CATEGORY_HOMEPAGE_PRODUCTSAPI,
      { storeid: storeid },
      false
    )
      .then(async (res) => {
        // console.log(res);
        if (res != typeof undefined && res.data != typeof undefined) {
          // console.log(res.data);

          //console.log(res);
          //console.log("data =="+res.data[0]);
          setCategorypageproducts(res.data);
          // console.log("data =="+res.data[1].products[0].imageurl);
        }
      })
      .catch(async (error) => {
        let errors = AxiosError(error);
        console.log(errors);
      });
  });

  const [isEnabled, setIsEnabled] = useState(true);

  const initializeWhatsappChatWidget = () => {
    if (!isEnabled) return;

    const isOnCheckoutPage = window.location.pathname === "/checkout";
    setIsEnabled(!isOnCheckoutPage); 
    const isOnCartPage = window.location.pathname === "/cart";
    setIsEnabled(!isOnCartPage); 

    if (isOnCheckoutPage) return;
    if (isOnCartPage) return;

    const url =
      "https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?12205";
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = url;
    const options = {
      enabled: isEnabled,
      chatButtonSetting: {
        backgroundColor: "#00e785",
        // ctaText: "Chat with us",
        borderRadius: "25",
        marginLeft: "0",
        marginRight: "20",
        marginBottom: "20",
        ctaIconWATI: false,
        position: "right",
      },
      brandSetting: {
        brandName: "Aathirai Craftz",
        brandSubTitle: "Aathirai Craftz",
        brandImg:
          "https://assetsvilva.blr1.cdn.digitaloceanspaces.com/store/6/favicon.webp",
        welcomeText: "Hi there!\nHow can I help you?",
        messageText:
          "Hello AathiraiCraftz! I’d like more information about your products. Could you assist with my enquiry? Thanks!",
        backgroundColor: "#00e785",
        ctaText: "Chat with us",
        borderRadius: "25",
        autoShow: false,
        phoneNumber: "+60167840475",
      },
    };
    script.onload = () => {
      window.CreateWhatsappChatWidget(options);
    };
    document.body?.appendChild(script);
  };

  useEffect(() => {
    if (!pageinit) {
      setPageInit(true);
      // categoryhomepageproducts();
      setUserData(getUserdata());
      initializeWhatsappChatWidget();
    }

    // // Check if the user is on the checkout page
    // const isOnCheckoutPage = window.location.pathname === '/checkout';
    // // If user is on the checkout page, disable the WhatsApp chat widget
    // setIsEnabled(!isOnCheckoutPage);
  }, []);

  // useEffect(() => {
  //   // Initialize the WhatsApp chat widget if it's enabled
  // }, [isEnabled]);

  const getcartCount = () => {
    return cartinfoData.cartcount;
  };
  const cartCount = cartinfoData.cartcount;
  // console.log(categoryListData)

  const mainCategories = categoryListData
    ?.sort((a, b) => {
      return a.sortorder - b.sortorder;
    })
    .filter((category) => category.parent_id === 0);
  // Then, sort the main categories based on their IDs
  mainCategories?.sort((a, b) => a.id - b.id);

  const [visibleSubcategories, setVisibleSubcategories] = useState([]);

  // Function to toggle visibility of subcategories
  const toggleSubcategories = (mainCategoryId) => {
    if (visibleSubcategories.includes(mainCategoryId)) {
      setVisibleSubcategories(
        visibleSubcategories.filter((id) => id !== mainCategoryId)
      );
    } else {
      setVisibleSubcategories([...visibleSubcategories, mainCategoryId]);
    }
  };

  const [hoveredCategory, setHoveredCategory] = useState(null);

  // Function to handle mouse enter and leave events
  const handleMouseEnter = (mainCategoryId) => {
    setHoveredCategory(mainCategoryId);
  };

  const handleMouseLeave = () => {
    setHoveredCategory(null);
    setHoveredSubCategory(null);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const navigate2 = useNavigate();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate2(`/products?search=${encodeURIComponent(searchQuery)}`);
  };

  const [hoveredSubCategory, setHoveredSubCategory] = useState(null);

  const getSubcategoriesByParentId = (parentId) => {
    return categoryListData?.filter(
      (category) => category.parent_id === parentId
    );
  };
  const getSubcategoriesBySubcatagory = (parentId) => {
    return categoryListData?.filter(
      (category) => category.parent_id === parentId
    );
  };

  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="border-0 p-0 m-0"
          >
            <HiOutlineBars3BottomLeft />
          </Navbar.Toggle>
          <Navbar.Brand href="#">
            {" "}
            <Link to="/">
              {" "}
              <img
                className="img  img-fluid me-4 me-md-1"
                width={"200px"}
                src={`https://assetsvilva.blr1.cdn.digitaloceanspaces.com/store/6/logo.webp`}
                alt=""
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-`}
            aria-labelledby={`offcanvasNavbarLabel-expand-`}
            placement="start"
            style={{ width: "80%" }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-`}>
                <Navbar.Brand href="#">
                  {" "}
                  <Link to="/">
                    {" "}
                    <img
                      className="img img-fluid me-4 me-md-1"
                      width={"200px"}
                      src={`https://assetsvilva.blr1.cdn.digitaloceanspaces.com/store/6/logo.webp`}
                      alt=""
                    />
                  </Link>
                </Navbar.Brand>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form className="d-md-flex d-none" onSubmit={handleSearchSubmit}>
                <Form.Control
                  style={{
                    width: "500px",
                    border: "none",
                    borderBottom: "2px solid black",
                  }}
                  className="ms-5 navbar-input rounded-0"
                  type="search"
                  placeholder="Search Products..."
                  aria-label="Search"
                  onChange={handleSearchChange}
                />
                <Button
                  className="navbar-input rounded-0"
                  type="submit"
                  variant="bg-dark"
                  style={{ border: "none", borderBottom: "2px solid black" }}
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    style={{ color: "#000000" }}
                  />
                </Button>
              </Form>

              <Nav className="ms-auto " style={{ maxHeight: "100px" }}>
                <Nav.Link href="#" className="">
                  {" "}
                  <div className="  items-center  border-l font-heading hover:text-gray-600">
                    <Link
                      to="/signin"
                      style={{
                        display: userData != null ? "none" : "flex",
                      }}
                    >
                      <div className=" d-md-flex d-none">
                        <FontAwesomeIcon
                          className="d-md-flex d-none"
                          icon={faUser}
                          style={{ color: "#000000" }}
                        />
                        <span className="ps-2" id="navbar-font">
                          {" "}
                          Login{" "}
                        </span>
                      </div>
                    </Link>
                    <a
                      className="dropdown d-md-flex d-none"
                      href={() => {
                        return false;
                      }}
                      style={{
                        display: userData === null ? "none" : "flex",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{
                          color: "#000000",
                          display: userData === null ? "none" : "flex",
                          cursor: "pointer",
                        }}
                      />

                      <span className="user-font">{userData?.name}</span>
                      <div
                        className="dropdown-content"
                        style={{ cursor: "pointer" }}
                      >
                        <Link
                          to="/myaccount"
                          className={
                            activeMenuItem === "myaccount" ? "active" : ""
                          }
                          onClick={() => handleItemClick("myaccount")}
                        >
                          My Account
                        </Link>
                        <Link
                          to="/orderhistory"
                          className={
                            activeMenuItem === "orderhistory" ? "active" : ""
                          }
                          onClick={() => handleItemClick("orderhistory")}
                        >
                          My Orders
                        </Link>
                        <Link
                          to="/mywallet"
                          className={
                            activeMenuItem === "mywallet" ? "active" : ""
                          }
                          onClick={() => handleItemClick("mywallet")}
                        >
                          My Wallet
                        </Link>
                        <hr
                          style={{
                            border: "1px solid #CFD5E2",
                            height: "2px",
                          }}
                        />
                        <Link to="/signin">Logout</Link>
                      </div>
                    </a>
                  </div>
                </Nav.Link>
                <Nav.Link href="#" className="pe-3 mb-2">
                  <Link to="/signin" style={{ color: "#000000" }}>
                    {" "}
                    <div className="d-flex">
                      <span className="ps-2" id="navbar-font">
                        {" "}
                      </span>
                    </div>{" "}
                  </Link>
                </Nav.Link>
                <Nav.Link href="#" className="ms-4 ms-md-0  d-none d-md-block">
                  {" "}
                  <div className="  items-center  border-l font-heading hover:text-gray-600">
                    <Link
                      className=" hover:text-gray-600"
                      to={!wishlist ? "/mywishlist" : "/products/brass-statues"}
                      onClick={() => {
                        setwishlist(!wishlist);
                        setCount({
                          ...count,
                          count: count + 1,
                        });
                      }}
                    >
                      <svg
                        width={23}
                        height={20}
                        viewBox="0 0 23 20"
                        fill={wishlist ? "#f45440" : "none"}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.4998 19.2061L2.70115 9.92527C1.92859 9.14433 1.41864 8.1374 1.24355 7.04712C1.06847 5.95684 1.23713 4.8385 1.72563 3.85053V3.85053C2.09464 3.10462 2.63366 2.45803 3.29828 1.96406C3.9629 1.47008 4.73408 1.14284 5.5483 1.00931C6.36252 0.875782 7.19647 0.939779 7.98144 1.19603C8.7664 1.45228 9.47991 1.89345 10.0632 2.48319L11.4998 3.93577L12.9364 2.48319C13.5197 1.89345 14.2332 1.45228 15.0182 1.19603C15.8031 0.939779 16.6371 0.875782 17.4513 1.00931C18.2655 1.14284 19.0367 1.47008 19.7013 1.96406C20.3659 2.45803 20.905 3.10462 21.274 3.85053V3.85053C21.7625 4.8385 21.9311 5.95684 21.756 7.04712C21.581 8.1374 21.071 9.14433 20.2984 9.92527L11.4998 19.2061Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </Nav.Link>

                <Nav.Link href="#" className="px-3  pe-5 me-3 d-md-flex d-none">
                  <Link
                    to="/cart"
                    style={{ color: "#000000", display: "flex" }}
                  >
                    <SlHandbag />
                    <div style={{ position: "relative" }}>
                      {cartCount === 0 ? (
                        <span
                          className="cartCount2"
                          style={{ display: "none" }}
                        >
                          {cartCount}
                        </span>
                      ) : (
                        <span className="cartCount2">{cartCount}</span>
                      )}
                    </div>
                  </Link>
                </Nav.Link>

                <div className=" mblnavbar mt-0 pt-0 d-md-none">
                  <div>
                    <div className="d-flex justify-content-end">
                      <Link
                        className="mx-3 hover:text-gray-600"
                        to={
                          !wishlist ? "/mywishlist" : "/products/brass-statues"
                        }
                        onClick={() => {
                          setwishlist(!wishlist);
                          setCount({
                            ...count,
                            count: count + 1,
                          });
                        }}
                      >
                        <svg
                          width={23}
                          height={20}
                          viewBox="0 0 23 20"
                          fill={wishlist ? "#f45440" : "none"}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.4998 19.2061L2.70115 9.92527C1.92859 9.14433 1.41864 8.1374 1.24355 7.04712C1.06847 5.95684 1.23713 4.8385 1.72563 3.85053V3.85053C2.09464 3.10462 2.63366 2.45803 3.29828 1.96406C3.9629 1.47008 4.73408 1.14284 5.5483 1.00931C6.36252 0.875782 7.19647 0.939779 7.98144 1.19603C8.7664 1.45228 9.47991 1.89345 10.0632 2.48319L11.4998 3.93577L12.9364 2.48319C13.5197 1.89345 14.2332 1.45228 15.0182 1.19603C15.8031 0.939779 16.6371 0.875782 17.4513 1.00931C18.2655 1.14284 19.0367 1.47008 19.7013 1.96406C20.3659 2.45803 20.905 3.10462 21.274 3.85053V3.85053C21.7625 4.8385 21.9311 5.95684 21.756 7.04712C21.581 8.1374 21.071 9.14433 20.2984 9.92527L11.4998 19.2061Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Link>
                      <Link
                        className="mx-3 "
                        to="/cart"
                        style={{ color: "#000000", display: "flex" }}
                      >
                        <SlHandbag />
                        <div style={{ position: "relative" }}>
                          {cartCount === 0 ? (
                            <span
                              className="cartCount2"
                              style={{ display: "none" }}
                            >
                              {cartCount}
                            </span>
                          ) : (
                            <span className="cartCount2">{cartCount}</span>
                          )}
                        </div>
                      </Link>
                    </div>
                    <h5 className="mt-0 pt-0 mb-3 mblmenuhead  pb-2">
                      SHOP IN
                    </h5>
                    {/* {mainCategories?.length > 0 ? (
                      mainCategories.map((category) => (
                        <div className="pb-2" key={category.id}>

                          <a className="uppercase mobilemenu " href={`/products/${category.name}`} value={category.id}>{category.name}</a>


                        </div>
                      ))
                    ) : (
                      <p>Loading categories...</p>
                    )} */}

                    {mainCategories
                      ?.sort((a, b) => a.sortorder - b.sortorder)
                      .map((mainCategory) => (
                        <div key={mainCategory.id}>
                          <a
                            className="ms-3 mobilemenu uppercase d-flex d-md-none"
                            href="#"
                            onClick={() => toggleSubcategories(mainCategory.id)}
                          >
                            <b>
                              {mainCategory.name}
                              {getSubcategoriesByParentId(mainCategory.id)
                                .length > 0 &&
                                (visibleSubcategories.includes(
                                  mainCategory.id
                                ) ? (
                                  <FontAwesomeIcon
                                    icon={faCaretDown}
                                    size="sm"
                                    style={{
                                      color: "#e51f4b",
                                      marginLeft: "5px",
                                    }}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    size="sm"
                                    style={{
                                      color: "#e51f4b",
                                      marginLeft: "5px",
                                    }}
                                  />
                                ))}
                            </b>
                          </a>

                          {visibleSubcategories.includes(mainCategory.id) &&
                            categoryListData
                              .filter(
                                (subCategory) =>
                                  subCategory.parent_id === mainCategory.id
                              )
                              .map((subCategory) => (
                                <div key={subCategory.id}>
                                  <a
                                    className="ms-3 submobilemenu text-sm uppercase d-flex align-items-center d-md-none"
                                    href={`/products/${subCategory.seo_url}`}
                                    onClick={() =>
                                      toggleSubcategories(subCategory.id)
                                    }
                                  >
                                    {subCategory.name}
                                    {getSubcategoriesByParentId(subCategory.id)
                                      .length > 0 &&
                                      (visibleSubcategories.includes(
                                        subCategory.id
                                      ) ? (
                                        <FontAwesomeIcon
                                          icon={faCaretDown}
                                          size="sm"
                                          style={{
                                            color: "#e51f4b",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faCaretRight}
                                          size="sm"
                                          style={{
                                            color: "#e51f4b",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      ))}
                                  </a>
                                  {visibleSubcategories.includes(
                                    subCategory.id
                                  ) &&
                                    categoryListData
                                      .filter(
                                        (insubCategory) =>
                                          insubCategory.parent_id ===
                                          subCategory.id
                                      )
                                      .map((insubCategory) => (
                                        <div key={insubCategory.id}>
                                          <a
                                            className="ms-5 submobilemenu text-sm uppercase d-flex d-md-none"
                                            href={`/products/${encodeURIComponent(
                                              insubCategory.name.toLowerCase()
                                            )}`}
                                          >
                                            {insubCategory.name}
                                          </a>
                                        </div>
                                      ))}
                                </div>
                              ))}
                        </div>
                      ))}
                  </div>
                </div>

                <h5
                  className="d-md-none mblmenuhead mb-3 pt-3 pb-1"
                  style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer;",
                  }}
                >
                  MY PROFILE
                </h5>
                <Link
                  to="/myaccount"
                  className="ms-3 mobilemenu uppercase d-flex d-md-none pb-2 "
                  style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer;",
                  }}
                >
                  <a
                    href=""
                    style={{
                      display: userData === null ? "none" : "flex",
                      cursor: "pointer;",
                    }}
                  >
                    My Account
                  </a>
                </Link>

                <Link
                  to="/orderhistory"
                  className="ms-3 uppercase mobilemenu d-flex d-md-none pb-2"
                  style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer;",
                  }}
                >
                  <a
                    href=""
                    style={{
                      display: userData === null ? "none" : "flex",
                      cursor: "pointer;",
                    }}
                  >
                    My Orders
                  </a>
                </Link>

                <Link
                  to={!wishlist ? "/mywishlist" : "/products/brass-statues"}
                  className="ms-3 uppercase mobilemenu d-flex d-md-none pb-2"
                  style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer;",
                  }}
                >
                  <a
                    href=""
                    style={{
                      display: userData === null ? "none" : "flex",
                      cursor: "pointer;",
                    }}
                  >
                    My Wallet
                  </a>
                </Link>

                <Link
                  href="#a"
                  className="ms-3 uppercase mobilemenu d-flex d-md-none "
                >
                  <Link
                    to="#"
                    style={{
                      display: userData === null ? "none" : "flex",
                      cursor: "pointer;",
                    }}
                  >
                    My Wishlist
                  </Link>
                </Link>

                <h5 className="d-flex d-md-none mblmenuhead my-3 pb-2">
                  CONTACT US
                </h5>
                <Link
                  to="/products"
                  className="ms-3 uppercase pb-2 mobilemenu d-flex d-md-none "
                >
                  Help & Support
                </Link>
                <Link
                  to="/products"
                  className="ms-3 uppercase mobilemenu pb-2 d-flex d-md-none "
                >
                  Feedback & Suggestions
                </Link>

                <Link
                  to="/products"
                  className="ms-3 uppercase mobilemenu pb-2 d-flex d-md-none "
                >
                  Request a Cell
                </Link>

                <h5 className="d-flex d-md-none mblmenuhead my-3">ABOUT US</h5>
                <Link
                  to="/store"
                  className="ms-3 uppercase pb-2 mobilemenu d-flex d-md-none "
                >
                  Our STORE
                </Link>
                <Link
                  to="/blog"
                  className="ms-3 uppercase mobilemenu pb-2 d-flex d-md-none "
                >
                  Blog
                </Link>
                <hr />

                <a
                  href="/signin"
                  className="ms-3 mobilemenu pb-2  d-md-none "
                  style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer;",
                  }}
                >
                  Log Out
                </a>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <Form
            className="d-flex d-sm-none inputshow"
            onClick={handleSearchSubmit}
          >
            <Form.Control
              style={{
                width: "100%",
                border: "none",
                borderBottom: "2px solid black",
              }}
              className="ms-3 mt-3  rounded-0"
              type="search"
              placeholder="Search Products..."
              aria-label="Search"
              onChange={handleSearchChange}
            />
            <Button
              type="submit"
              className="rounded-0"
              variant="bg-dark"
              style={{ border: "none", borderBottom: "2px solid black" }}
            >
              <IoSearchOutline />
            </Button>
          </Form>
        </Container>
      </Navbar>
      <div className="d-none d-md-flex  mt-0 pt-0">
        <div className="allcatgories flex items-center">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="bars"
            class="svg-inline--fa fa-bars d-none d-md-block"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            style={{ color: "rgb(255, 255, 255)" }}
          >
            <path
              fill="currentColor"
              d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
            ></path>
          </svg>
          &nbsp;&nbsp;
          <a href="/products/brass-statues" style={{ color: "white" }}>
            ALL CATEGORIES
          </a>
        </div>

        <div className="Categorymenu ">
          {mainCategories
            ?.sort((a, b) => a.sortorder - b.sortorder)
            .slice(0, 8)
            .map((mainCategory) => (
              <div
                key={mainCategory.id}
                className="desknav-container"
                onMouseEnter={() => setHoveredCategory(mainCategory.id)}
                onMouseLeave={handleMouseLeave}
                onClick={() => toggleSubcategories(mainCategory.id)}
              >
                <a
                  className="ms-3 desknav uppercase"
                  href={`/products/${mainCategory.seo_url}`}
                >
                  <b>{mainCategory.name}</b>
                </a>
                {hoveredCategory === mainCategory.id && (
                  <div className="desknav2">
                    {categoryListData
                      .filter(
                        (subCategory) =>
                          subCategory.parent_id === mainCategory.id
                      )
                      .map((subCategory) => (
                        <div
                          key={subCategory.id}
                          className="subcategory-container p-1"
                          onMouseEnter={() =>
                            setHoveredSubCategory(subCategory.id)
                          }
                          // onMouseLeave={handleMouseLeave}
                        >
                          <a
                            className="ms-2 text-sm desknav2a  uppercase"
                            href={`/products/${subCategory.seo_url}`}
                          >
                            {subCategory.name}{" "}
                            {getSubcategoriesByParentId(subCategory.id).length >
                              0 && (
                              <FontAwesomeIcon
                                icon={
                                  hoveredSubCategory === subCategory.id
                                    ? faChevronDown
                                    : faAngleRight
                                }
                                size="sm"
                                style={{ color: "#000000" }}
                              />
                            )}
                          </a>
                          {hoveredSubCategory === subCategory.id && (
                            <div className="desknav3">
                              {categoryListData
                                .filter(
                                  (subSubCategory) =>
                                    subSubCategory.parent_id === subCategory.id
                                )
                                .map((subSubCategory) => (
                                  <div key={subSubCategory.id}>
                                    <a
                                      className="ms-2 text-sm desknav3a  uppercase"
                                      href={`/products/${subSubCategory.seo_url}`}
                                    >
                                      {subSubCategory.name}
                                    </a>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            ))}
          <a href="/blog" target="_blank" className="ps-3 desknav">
            <b>BLOG</b>
          </a>
        </div>
      </div>
    </>
  );
}
