import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import {
  callCategoryList,
  callColorList,
  callCountryList,
  callMockCategoryList,
  callOrderStatusList,
  callProductsColorList,
  callProductsList,
  callProductsSizeList,
  callVariationsList,
  callSizeList,
  callStores,
  callVersionMangerList,
  callWeightList,
  callStateList,
} from "./utilities/storeManager.js";
import { getUserdata } from "./utilities/sessionexpiry.js";
import {
  handleGetInfoStorageItems,
  handleSetInfoStorageItems,
} from "./utilities/storageManager.js";
import { SK } from "./constants/StorageKeys.js";
import {
  handleGetCartInfoStorageItems,
  handleSetCartInfoStorageItems,
} from "./utilities/cartManager.js";
import { checkerString, lowercasenosp } from "./utilities/checker.js";

import { Ordersuccess } from "./pages/Ordersuccess.js";

const NotfoundPage = React.lazy(() => import("./pages/Notfoundpage.js"));
const BlogDetailView = React.lazy(() => import("./pages/blog-detail-view.js"));
const Blogpage = React.lazy(() => import("./pages/blogpage.js"));
const MyProfile = React.lazy(() => import("./pages/myprofile.js"));
const ContactUs = React.lazy(() => import("./pages/Contactus.js"));
const OrderHistoryPage = React.lazy(() => import("./pages/orderhistory.js"));
const ChangePassword = React.lazy(() => import("../src/pages/changepassword.js"));
const Myaddress = React.lazy(() => import("./pages/Myaddress.js"))
const HomePageLazy = React.lazy(() => import("./pages/Homepage.jsx"));
const OrderDetails = React.lazy(() => import("./pages/orderdetails.js"));
const NavbarMain = React.lazy(() => import("./pages/navbarmain.js"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacypolicy.js"));
const TrackOrder = React.lazy(() => import("./pages/trackorder.js"));
const TermsAndCondtions = React.lazy(() => import("./pages/termsandconditions.js"));
const ShippingAndRefundPolicy = React.lazy(() => import("./pages/shippingandrefundpolicy.js"));
const Myaccount = React.lazy(() => import("./pages/Myaccount.js"));
const Mywallet = React.lazy(() => import("./pages/Mywallet.js"));
const MyWishlistPage = React.lazy(() => import("./pages/mywishlist.js"));
const ProductViewPage = React.lazy(() => import("./pages/product-info.js"));
const ProductsPage = React.lazy(() => import("./pages/products.js"));
const CheckOutPage = React.lazy(() => import("./pages/checkout.js"));
const SignInPage = React.lazy(() => import("./pages/signin.js"));
const SignUpPage = React.lazy(() => import("./pages/signup.js"));
const DynamicPages = React.lazy(() =>
  import("./pages/dynamicpages.js")
);

const SignInEmailPage = React.lazy(() => import("./pages/signinwithemail.js"));

import CartPage from "./pages/cart.js";
import UppyFileUpload from "./pages/uppyfileupload.js";
import Customize from "./pages/customize.js";
// import Sizechart from "./pages/sizechart.js";
// import FaqPage from "./pages/Faqpage.js";
function App(props) {
  const [pageinit, setPageInit] = useState(false);
  const [assetsUrl, setAssetsUrl] = useState(null);
  const [storeinfo, setStoreInfo] = useState({
    storeid: null,
    store: "",
    description: "",
    assets: "",
    hdimage: "",
    gateway_config: {},
    productimage: "",
    productviewimage: "",
    thumbnailviewimage: "",
  });
  const [categoryListData, setCategoryListData] = useState([]);
  const [userData, setUserData] = useState({});
  const [showFooter, setshowFooter] = useState(true);
  const [sizeListData, setSizeListData] = useState([]);
  const [weightListData, setWeightListData] = useState([]);
  const [variationsListData, setVariationsListData] = useState([]);
  const [displaycartcouponlist, setdisplaycartcouponlist] = useState([]);
  const [cartcouponlist, setcartcouponlist] = useState([]);

  const [productsListData, setProductsListData] = useState([]);
  const [orderstatusListData, setOrderStatusListData] = useState([]);
  const [productssizeListData, setProductsSizeListData] = useState([]);
  const [productscolorcodesListData, setProductsColorCodesListData] = useState(
    []
  );
  // const [logoImageUrl, setLogoImageUrl] = useState(
  //   `/yofte-assets/logos/${lowercasenosp(store)}/logo.webp`
  // );
  const [colorcodesListData, setColorCodesListData] = useState([]);
  const [countryListData, setCountryListData] = useState([]);
  const [stateListData, setStateListData] = useState([]);
  const [mobileprefix, setMobilePrefix] = useState("+60");
  const [suppcountryListData, setSuppCountryListData] = useState({});
  const [versionmanagerListData, setVersionManagerListData] = useState([]);
  const [productwishlistData, setproductwishlistData] = useState([]);
  const [wishlist, setwishlist] = useState(false);
  const [ongatewayTypes, setongatewayTypes] = useState([]);
  const [count, setCount] = useState(1);
  let cartinfodefalults = {
    cartcount: 0,
    cartquantitycount: 0,
    products: [],
    cartprice: 0,
    subtotal: 0,
    mrptotal: 0,
    shipping: 0,
    ordertotal: 0,
    total: 0,
  };

  let storetextdefalults = {
    storeid: process.env.REACT_APP_STORE_ID,
    storecode: process.env.REACT_APP_STORE_CODE,
    footercopyrighttext: "",
    mainpagebannerbgcolor: "#ffffff",
    mainpagebanner1text: "",
    mainpagebanner2text: "",
    mainpageheader1text: "",
    mainpageheader2text: "",
  };

  const [cartinfoData, setCartInfoData] = useState(cartinfodefalults);
  const [storeText, setStoreText] = useState(storetextdefalults);

  useEffect(() => {
    console.log("***");
  }, [count]);

  const clearCartInfoData = async () => {
    handleSetCartInfoStorageItems(cartinfodefalults);
    setCartInfoData(cartinfodefalults);
    setProductsListData(await callProductsList(null, true, true));
    setCount({ ...count, count: count + 1 });
  };

  const pageRefresh = async (productlist = false) => {
    setUserData(getUserdata());
    setCount({ ...count, count: count + 1 });
    if (productlist) {
      let hardreset = false;
      let mockupdata = true;
      let _productlistdata = await callProductsList(
        null,
        hardreset,
        mockupdata
      );
      setProductsListData(_productlistdata === null ? [] : _productlistdata);
      setCount({ ...count, count: count + 1 });
      return _productlistdata;
    }
    return await handleGetCartInfoStorageItems(setCartInfoData);
  };

  const callpageInit = async () => {
    let version = handleGetInfoStorageItems(SK.VERSIONINFODATA);
    let hardreset = false;
    let mockupdata = false;
    const currentVersion = Number(process.env.REACT_APP_VERSION);

    // Check if the stored version is invalid or doesn't match the current version
    if (isNaN(currentVersion) || Number(version) !== currentVersion) {
      // Update version info in local storage
      handleSetInfoStorageItems(SK.VERSIONINFODATA, currentVersion);

      // Increment count state
      setCount({ ...count, count: count + 1 });

      // Set mockupdata flag to true
      mockupdata = true;
    }

    // setAssetsUrl(await callStores("assets", hardreset, true));
    let storedetails = await callStores(null, hardreset, mockupdata);
    if (storedetails) setStoreInfo(storedetails);
    let gateway_config = storedetails?.gateway_config
      ? JSON.parse(storedetails?.gateway_config) ?? []
      : [];
    let couponse_config = storedetails?.coupons ?? [];
    setdisplaycartcouponlist(couponse_config);


    let _ongatewayTypes = [];
    gateway_config.map((gt_config) => {
      if (gt_config?.status === "1" || gt_config?.status === 1)
        _ongatewayTypes.push(gt_config?.gateway);
    });
    //storedetails.gateway_config = gateway_config;
    // console.log("gateway_config")
    // console.log(gateway_config)
    setongatewayTypes(_ongatewayTypes);
    let region = storedetails?.region
      ? JSON.parse(storedetails?.region) ?? {}
      : {};
    let suppcount = [];
    suppcount.push({ id: region?.id, default: 1 });
    region?.supported_countries?.map((_supp) => {
      suppcount.push({ id: _supp?.id, default: 0 });
    });

    setSuppCountryListData(suppcount);
    let _categorylistdata = await await callCategoryList(
      null,
      hardreset,
      mockupdata
    );
    setCategoryListData(_categorylistdata === null ? [] : _categorylistdata);

    let _countrylistdata = await callCountryList(null, hardreset, mockupdata);
    setCountryListData(_countrylistdata === null ? [] : _countrylistdata);

    let _statelistdata = await callStateList(null, hardreset, mockupdata);
    setStateListData(_statelistdata === null ? [] : _statelistdata);
    console.log("***_statelistdata")
    console.log(_statelistdata)

    let _sizelistdata = await callSizeList(null, hardreset, mockupdata);
    setSizeListData(_sizelistdata === null ? [] : _sizelistdata);

    let _productlistdata = await callProductsList(null, hardreset, mockupdata);
    setProductsListData(_productlistdata === null ? [] : _productlistdata);

    let _orderstatuslistddata = await callOrderStatusList(
      null,
      hardreset,
      mockupdata
    );
    setOrderStatusListData(
      _orderstatuslistddata === null ? [] : _orderstatuslistddata
    );

    let _prodictsizelistdata = await callProductsSizeList(
      null,
      hardreset,
      mockupdata
    );
    setProductsSizeListData(
      _prodictsizelistdata === null ? [] : _prodictsizelistdata
    );

    let _colorcodelistdata = await callColorList(null, hardreset, mockupdata);
    setColorCodesListData(
      _colorcodelistdata === null ? [] : _colorcodelistdata
    );

    let _weightlistdata = await callWeightList(null, hardreset, mockupdata);
    setWeightListData(_weightlistdata === null ? [] : _weightlistdata);

    let _variationlistdata = await callVariationsList(
      null,
      hardreset,
      mockupdata
    );
    setVariationsListData(
      _variationlistdata === null ? [] : _variationlistdata
    );

    let _productscolorcodelistdata = await callProductsColorList(
      null,
      hardreset,
      mockupdata
    );
    setProductsColorCodesListData(
      _productscolorcodelistdata === null ? [] : _productscolorcodelistdata
    );

    setVersionManagerListData(
      await callVersionMangerList(null, hardreset, mockupdata)
    );

    if (hardreset) {
      clearCartInfoData();
    } else {
      handleGetCartInfoStorageItems(setCartInfoData);
    }

    if (lowercasenosp(storedetails?.store) === lowercasenosp("tamiltshirts")) {
      storetextdefalults.footercopyrighttext = `© Copyright 2012 - ${new Date().getFullYear()} Tamil Tshirts`;
      storetextdefalults.mainpagebanner1text = "Tamil Printed Tshirts Factory";
      storetextdefalults.mainpagebanner2text = "Customized Printed Tshirts";
      storetextdefalults.mainpageheader1text =
        "Elevate Your Style with Unique <br/> Printed T-Shirts.";
      storetextdefalults.mainpageheader2text =
        "Discover more products and inspiration.";

      setStoreText(storetextdefalults);
    } else if (
      lowercasenosp(storedetails?.store) === lowercasenosp("teemania")
    ) {
      storetextdefalults.footercopyrighttext = `© Copyright ${new Date().getFullYear()} Teemania`;
      storetextdefalults.mainpagebanner1text =
        "Beautiful Quotes Printed Tshirts Factory";
      storetextdefalults.mainpagebanner2text = "Customized Printed Tshirts";
      storetextdefalults.mainpageheader1text =
        "Elevate Your Style with Unique <br/> Printed T-Shirts.";
      storetextdefalults.mainpageheader2text =
        "Discover more products and inspiration.";
      setStoreText(storetextdefalults);
    } else if (
      lowercasenosp(storedetails?.store) === lowercasenosp("thaithingal")
    ) {
      storetextdefalults.mainpagebannerbgcolor = "#d6aa33";
      storetextdefalults.footercopyrighttext = `© Copyright ${new Date().getFullYear()} ThaiThingal`;
      storetextdefalults.mainpagebanner1text = "";
      storetextdefalults.mainpagebanner2text = "";
      storetextdefalults.mainpageheader1text = "";
      storetextdefalults.mainpageheader2text = "";
      setStoreText(storetextdefalults);
    }

    setCount({ ...count, count: count + 1 });

    // setAssetsUrl(await callStores("assets"));
    // setCategoryListData(await callCategoryList());
    // setSizeListData(await callSizeList());
    // setProductsListData(await callProductsList());
    // setProductsSizeListData(await callProductsSizeList());
    // setColorCodesListData(await callColorList());
    // setProductsColorCodesListData(await callProductsColorList());
    // setVersionManagerListData(await callVersionMangerList());

    setUserData(getUserdata());
    setCount({ ...count, count: count + 1 });
  };

  if (!pageinit) {
    setPageInit(true);
    callpageInit();
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<Suspense fallback={<div style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><img
          src="https://assetsvilva.blr1.cdn.digitaloceanspaces.com/store/6/favicon.webp"
          alt="Aathirai Logo"
        ></img>
        </div>
        }
        >
          <HomePageLazy

            wishlist={wishlist}
            setwishlist={setwishlist}
            productwishlistData={productwishlistData}
            setproductwishlistData={setproductwishlistData}
            categoryListData={categoryListData}
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            social={storeinfo.social}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            storecode={storeText.storecode}
            hdimage={
              checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""
            }
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            cartcouponlist={cartcouponlist}
            setcartcouponlist={setcartcouponlist}
            count={count}
            setCount={setCount}
            assetsUrl={assetsUrl}
            productsListData={productsListData}
            userData={userData}
            setUserData={setUserData}
            sizeListData={sizeListData}
            productssizeListData={productssizeListData}
            productscolorcodesListData={productscolorcodesListData}
            colorcodesListData={colorcodesListData}
            versionmanagerListData={versionmanagerListData}
            cartinfoData={cartinfoData}
            setCartInfoData={setCartInfoData}
            clearCartInfoData={clearCartInfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        </Suspense>}
      />

      <Route
        path="/trackorder"
        element={
          <Suspense>
            <TrackOrder
              store={storeinfo.store}
              shipping_config={storeinfo.shipping_config}
              description={storeinfo.description}
              assets={storeinfo.assets}
              storeid={storeText.storeid}
              storecode={storeText.storecode}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={
                checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""
              }
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              count={count}
              setCount={setCount}
              wishlist={wishlist}
              setwishlist={setwishlist}
              assetsUrl={assetsUrl}
              categoryListData={categoryListData}
              productsListData={productsListData}
              userData={userData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              orderstatusListData={orderstatusListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>
        }
      />

      <Route
        path="/privacypolicy"
        element={
          <Suspense>
            <PrivacyPolicy
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={
                checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""
              }
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>
        }
      />
      <Route
        path="/pages/:slug?"
        element={
          <Suspense>
            <DynamicPages
              store={storeinfo.store}
              shipping_config={storeinfo.shipping_config}
              description={storeinfo.description}
              assets={storeinfo.assets}
              storeid={storeText.storeid}
              storecode={storeText.storecode}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={
                checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""
              }
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              count={count}
              setCount={setCount}
              wishlist={wishlist}
              setwishlist={setwishlist}
              assetsUrl={assetsUrl}
              categoryListData={categoryListData}
              productsListData={productsListData}
              userData={userData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>
        }
      />


      <Route
        path="/shippingandrefundpolicy"
        element={
          <Suspense>
            <ShippingAndRefundPolicy
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={
                checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""
              }
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>
        }
      />
      <Route
        path="/cart"
        element={
          <CartPage
            displaycartcouponlist={displaycartcouponlist}
            setdisplaycartcouponlist={setdisplaycartcouponlist}
            wishlist={wishlist}
            setwishlist={setwishlist}
            productwishlistData={productwishlistData}
            setproductwishlistData={setproductwishlistData}
            categoryListData={categoryListData}
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            social={storeinfo.social}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            cartcouponlist={cartcouponlist}
            setcartcouponlist={setcartcouponlist}
            count={count}
            setCount={setCount}
            assetsUrl={assetsUrl}
            productsListData={productsListData}
            userData={userData}
            setUserData={setUserData}
            sizeListData={sizeListData}
            productssizeListData={productssizeListData}
            productscolorcodesListData={productscolorcodesListData}
            colorcodesListData={colorcodesListData}
            versionmanagerListData={versionmanagerListData}
            cartinfoData={cartinfoData}
            setCartInfoData={setCartInfoData}
            clearCartInfoData={clearCartInfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/checkout"
        element={
          <Suspense>
            <CheckOutPage
              countryListData={countryListData}
              setCountryListData={setCountryListData}
              stateListData={stateListData}
              setStateListData={setStateListData}
              mobileprefix={mobileprefix}
              setMobilePrefix={setMobilePrefix}
              suppcountryListData={suppcountryListData}
              setSuppCountryListData={setSuppCountryListData}
              displaycartcouponlist={displaycartcouponlist}
              setdisplaycartcouponlist={setdisplaycartcouponlist}
              ongatewayTypes={ongatewayTypes}
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />
      <Route
        path="/ordersuccess"
        element={
          <Suspense>
            <Ordersuccess
              displaycartcouponlist={displaycartcouponlist}
              setdisplaycartcouponlist={setdisplaycartcouponlist}
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />
      <Route
        path="/orderhistory"
        element={
          <Suspense>
            <OrderHistoryPage
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              orderstatusListData={orderstatusListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />

      <Route
        path="/orderdetails"
        element={
          <Suspense>
            <OrderDetails
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              orderstatusListData={orderstatusListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />
      <Route
        path="/blogdetailview"
        element={
          <Suspense>
            <BlogDetailView
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />
      <Route
        path="/products/:id?"
        element={
          <Suspense>
            <ProductsPage
              displaycartcouponlist={displaycartcouponlist}
              setdisplaycartcouponlist={setdisplaycartcouponlist}
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={
                checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""
              }
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>
        }
      />

      <Route
        path="/navbarmain"
        element={
          <NavbarMain
            social={storeText.social}
            wishlist={wishlist}
            setwishlist={setwishlist}
            productwishlistData={productwishlistData}
            setproductwishlistData={setproductwishlistData}
            categoryListData={categoryListData}
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            cartcouponlist={cartcouponlist}
            setcartcouponlist={setcartcouponlist}
            count={count}
            setCount={setCount}
            assetsUrl={assetsUrl}
            productsListData={productsListData}
            userData={userData}
            setUserData={setUserData}
            sizeListData={sizeListData}
            productssizeListData={productssizeListData}
            productscolorcodesListData={productscolorcodesListData}
            colorcodesListData={colorcodesListData}
            versionmanagerListData={versionmanagerListData}
            cartinfoData={cartinfoData}
            setCartInfoData={setCartInfoData}
            clearCartInfoData={clearCartInfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/p/:id"
        element={
          <Suspense>
            <ProductViewPage
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={
                checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""
              }
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              variationsListData={variationsListData}
              weightListData={weightListData}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />
      {/* <Route
        path="/signup"
        element={
          <Suspense>
          <SignUpPage
          countryListData={countryListData}
          setCountryListData={setCountryListData}
          mobileprefix={mobileprefix}
          setMobilePrefix={setMobilePrefix}
          suppcountryListData={suppcountryListData}
          setSuppCountryListData={setSuppCountryListData}
            wishlist={wishlist}
            setwishlist={setwishlist}
            productwishlistData={productwishlistData}
            setproductwishlistData={setproductwishlistData}
            categoryListData={categoryListData}
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            social={storeinfo.social}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            cartcouponlist={cartcouponlist}
            setcartcouponlist={setcartcouponlist}
            count={count}
            setCount={setCount}
            assetsUrl={assetsUrl}
            productsListData={productsListData}
            userData={userData}
            setUserData={setUserData}
            sizeListData={sizeListData}
            productssizeListData={productssizeListData}
            productscolorcodesListData={productscolorcodesListData}
            colorcodesListData={colorcodesListData}
            versionmanagerListData={versionmanagerListData}
            cartinfoData={cartinfoData}
            setCartInfoData={setCartInfoData}
            clearCartInfoData={clearCartInfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
       </Suspense> }
      /> */}
      <Route
        path="/signin"
        element={<Suspense>
          <SignInPage
            storecode={storeText.storecode}
            countryListData={countryListData}
            setCountryListData={setCountryListData}
            mobileprefix={mobileprefix}
            setMobilePrefix={setMobilePrefix}
            suppcountryListData={suppcountryListData}
            setSuppCountryListData={setSuppCountryListData}
            wishlist={wishlist}
            setwishlist={setwishlist}
            productwishlistData={productwishlistData}
            setproductwishlistData={setproductwishlistData}
            categoryListData={categoryListData}
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            social={storeinfo.social}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            cartcouponlist={cartcouponlist}
            setcartcouponlist={setcartcouponlist}
            count={count}
            setCount={setCount}
            assetsUrl={assetsUrl}
            productsListData={productsListData}
            userData={userData}
            setUserData={setUserData}
            sizeListData={sizeListData}
            productssizeListData={productssizeListData}
            productscolorcodesListData={productscolorcodesListData}
            colorcodesListData={colorcodesListData}
            versionmanagerListData={versionmanagerListData}
            cartinfoData={cartinfoData}
            setCartInfoData={setCartInfoData}
            clearCartInfoData={clearCartInfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        </Suspense>}
      />
      <Route
        path="/signin/email"
        element={
          <Suspense>
            <SignInEmailPage

              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              storeid={storeText.storeid}
              storecode={storeText.storecode}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={
                checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""
              }
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              count={count}
              setCount={setCount}
              wishlist={wishlist}
              setwishlist={setwishlist}
              assetsUrl={assetsUrl}
              categoryListData={categoryListData}
              countryListData={countryListData}
              setCountryListData={setCountryListData}
              mobileprefix={mobileprefix}
              setMobilePrefix={setMobilePrefix}
              suppcountryListData={suppcountryListData}
              setSuppCountryListData={setSuppCountryListData}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>
        }
      />
      <Route
        path="/customize"
        element={
          <UppyFileUpload
            wishlist={wishlist}
            setwishlist={setwishlist}
            productwishlistData={productwishlistData}
            setproductwishlistData={setproductwishlistData}
            categoryListData={categoryListData}
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            social={storeinfo.social}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            cartcouponlist={cartcouponlist}
            setcartcouponlist={setcartcouponlist}
            count={count}
            setCount={setCount}
            assetsUrl={assetsUrl}
            productsListData={productsListData}
            userData={userData}
            setUserData={setUserData}
            sizeListData={sizeListData}
            productssizeListData={productssizeListData}
            productscolorcodesListData={productscolorcodesListData}
            colorcodesListData={colorcodesListData}
            versionmanagerListData={versionmanagerListData}
            cartinfoData={cartinfoData}
            setCartInfoData={setCartInfoData}
            clearCartInfoData={clearCartInfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/changepassword"
        element={
          <Suspense>
            <ChangePassword
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />

      <Route
        path="/blog"
        element={
          <Suspense>
            <Blogpage
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />
      <Route
        path="/myprofile"
        element={
          <Suspense>
            <MyProfile
              displaycartcouponlist={displaycartcouponlist}
              setdisplaycartcouponlist={setdisplaycartcouponlist}
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />
      <Route
        path="/mywallet"
        element={
          <Suspense>
            <Mywallet
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />
      <Route
        path="/mywishlist"
        element={
          <Suspense>
            <MyWishlistPage
              displaycartcouponlist={displaycartcouponlist}
              setdisplaycartcouponlist={setdisplaycartcouponlist}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={
                checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""
              }
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              count={count}
              setCount={setCount}
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              assetsUrl={assetsUrl}
              categoryListData={categoryListData}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>
        }
      />


      <Route
        path="/myaccount"
        element={
          <Suspense>
            <Myaccount
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />
      <Route
        path="/myaddress"
        element={
          <Suspense>
            <Myaddress
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />
      <Route
        path="/termsandcondtions"
        element={
          <Suspense>
            <TermsAndCondtions
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />
      <Route
        path="/contactus"
        element={
          <Suspense>
            <ContactUs
              wishlist={wishlist}
              setwishlist={setwishlist}
              productwishlistData={productwishlistData}
              setproductwishlistData={setproductwishlistData}
              categoryListData={categoryListData}
              store={storeinfo.store}
              description={storeinfo.description}
              assets={storeinfo.assets}
              social={storeinfo.social}
              storeid={storeText.storeid}
              footercopyrighttext={storeText.footercopyrighttext}
              hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
              productimage={
                checkerString(storeinfo.productimage)
                  ? storeinfo.productimage
                  : ""
              }
              productviewimage={
                checkerString(storeinfo.productviewimage)
                  ? storeinfo.productviewimage
                  : ""
              }
              thumbnailviewimage={
                checkerString(storeinfo.thumbnailviewimage)
                  ? storeinfo.thumbnailviewimage
                  : ""
              }
              cartcouponlist={cartcouponlist}
              setcartcouponlist={setcartcouponlist}
              count={count}
              setCount={setCount}
              assetsUrl={assetsUrl}
              productsListData={productsListData}
              userData={userData}
              setUserData={setUserData}
              sizeListData={sizeListData}
              productssizeListData={productssizeListData}
              productscolorcodesListData={productscolorcodesListData}
              colorcodesListData={colorcodesListData}
              versionmanagerListData={versionmanagerListData}
              cartinfoData={cartinfoData}
              setCartInfoData={setCartInfoData}
              clearCartInfoData={clearCartInfoData}
              pageRefresh={pageRefresh}
              showFooter={showFooter}
              setshowFooter={setshowFooter}
            />
          </Suspense>}
      />


      <Route
        path="/old"
        element={
          <Customize
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route path="*" element={<NotfoundPage />} />
    </Routes>
  );
}

export default App;